import { FunctionComponent, useContext, useEffect } from 'react'
import { UserContext } from '../contexts/user'
import { ECookieName } from '../types/general'
import { setCookie } from '../utils/cookies'
import { renewAuthToken, renewSessionAuthToken } from './auth0'

const withAuth = (
  InnerComponent: FunctionComponent<any>
): FunctionComponent => {
  return function Page(props) {
    const { user, orgless } = useContext(UserContext)

    if (!user) {
      let error = ''

      if (orgless) {
        error = 'You need to be part of an organization to access the Console.'
      }

      return <RedirectTo uri={`/login?error=${error}`} storeCurrentUrl={true} />
    }

    return (
      <>
        <AuthTokenChecker />
        <InnerComponent {...props} />
      </>
    )
  }
}

const RedirectTo: FunctionComponent<{
  uri: string
  storeCurrentUrl?: boolean
}> = ({ uri, storeCurrentUrl }) => {
  if (storeCurrentUrl) {
    setCookie(
      ECookieName.BEFORE_LOGIN_URL,
      window.location.href.replace('#fromLogin', ''),
      {
        path: '/',
      }
    )
  }

  window.location.assign(uri)
  return null
}

const AuthTokenChecker: FunctionComponent = () => {
  useEffect(() => {
    const interval = setInterval(
      () => renewAuthToken().then(() => renewSessionAuthToken()),
      10000
    )
    return () => clearInterval(interval)
  }, [])

  return null
}

export default withAuth
