import { useRouter } from 'next/router'
import { FunctionComponent, useEffect } from 'react'
import withAuth from '../auth/withAuth'

const IndexPage: FunctionComponent = () => {
  const router = useRouter()

  useEffect(() => {
    router.replace('/explore', '/explore')
  }, [])

  return null
}

export default withAuth(IndexPage)
